.otpPage {
  /* font-family: Inter, 'Helvetica', sans-serif; */
  font-family: "Poppins", sans-serif;
  background-image: linear-gradient(to right, #50b5e7, #2ebf91);

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpPanel {
  background-color: white;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.otpPanelComp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.OTPTitle {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.OTPTitleText {
  color: #181c32;
  font-weight: 600;
  font-size: 23px;
  line-height: 27px;
}

.OTPInstructionText {
  color: #a1a5b7;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.OTPNumberText {
  color: #181c32;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
}

.inputStyle {
  /* width: 35% !important; */
  height: 45px;
  width: 25% !important;
  border-radius: 7px;
  border: 1px solid #e1e3ea;
  /* margin-left: 8px; */
  margin-right: 20px;
  background: white;
  font-size: 20px;
}

.OTPInput {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.OTPInputLabel {
  color: #181c32;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: left;
}

.OTPSubmit {
  font-size: 16px;
  line-height: 22px;
  color: white;
  font-weight: 600;

  background: #009ef7;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.OTPNumberUpdate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.OTPNumberUpdateComp {
  text-align: left;
  font-weight: 500;
  color: #a1a5b7;
  font-size: 17px;
  line-height: 22px;
}

.OTPNumberUpdateComp span:hover {
  cursor: pointer;
}

@media screen and (min-width: 650px) and (max-width: 1200px) {
  .otpPanel {
    width: 45%;
  }

  .inputStyle {
    /* width: 35% !important; */
    height: 25px;
    width: 45% !important;
    border-radius: 7px;
    border: 1px solid #e1e3ea;
    /* margin-left: 8px; */
    margin-right: 13px;
    background: white;
    font-size: 20px;
  }

  .OTPNumberUpdateComp {
    text-align: left;
    font-weight: 500;
    color: #a1a5b7;
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 350px) and (max-width: 650px) {
  .otpPanel {
    width: 55% !important;
  }

  .OTPNumberUpdateComp {
    text-align: left;
    font-weight: 500;
    color: #a1a5b7;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .inputStyle {
    /* width: 35% !important; */
    height: 25px;
    width: 45% !important;
    border-radius: 7px;
    border: 1px solid #e1e3ea;
    /* margin-left: 8px; */
    margin-right: 10px;
    background: white;
    font-size: 20px;
  }
}

@media screen and (min-width: 200px) and (max-width: 350px) {
  .otpPanel {
    width: 75% !important;
  }
  .inputStyle {
    /* width: 35% !important; */
    height: 25px;
    width: 45% !important;
    border-radius: 7px;
    border: 1px solid #e1e3ea;
    /* margin-left: 8px; */
    margin-right: 3px;
    background: white;
    font-size: 20px;
  }
}

@media screen and (min-height: 200px) and (max-height: 550px) {
  .otpPanel {
    height: fit-content !important;
  }
}
