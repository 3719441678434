:root {
  --bgGray: #f4f4f4;
  --pHGray: #a1a5b7;
  --darkGray: #5e6278;
}

.navigationBoxSettings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 23%;
  height: fit-content;
  position: fixed;
  /* background: white; */
}

.boxWithUrlProfile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  gap: 7px;
  border-radius: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  padding: 10px;
}
.navigationBoxUsername {
  display: flex;
  /* background: white; */
  gap: 7px;
  /* border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px; */
}
.navigationBoxOptions {
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  justify-content: center;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: white;
  /* background: #fcfcfc; */
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.navBoxOption {
  display: flex;
  gap: 10px;
}

.navBoxOption p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
.navBoxOption p:hover {
  color: #009ef7;
  cursor: pointer;
}
.navBoxOption svg {
  margin-top: 3px;
}

.navBButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  gap: 20px;
  padding-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navBCancelButton {
  width: 50%;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding: 5px;
  background: var(--pHGray);
}
.navBSaveButton {
  width: 50%;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  font-weight: 500;
  background: #009ef7;
}

.navBCancelButton:hover {
  cursor: pointer;
}

.navBSaveButton:hover {
  cursor: pointer;
}
