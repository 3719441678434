:root {
  --primary_color: #1f1e2e;
  --secondary_color: #1e89d9;
}
.sidebarMain {
  display: flex; 
  background: #1e1e2d;
}

.sidebarLeftTop {
  margin-top: 57px;
  display: flex;
  flex-direction: column;
  gap: 0.82rem;
  margin-right: 10px;
  margin-left: 10px;
}

.sidebar {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
  background: var(--primary_color);
  width: 220px;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.2), 0 2px 30px 0 rgba(0, 0, 0, 0.35); */
}
.imageHolder {
  margin-top: 13px;

  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 0px;
  /* background: white; */
  
}

.imageHolder img {
  color: white;
  margin-left: 10px;
}

.imageHolderAndStoreName {
  margin: 0 20px;
  margin-top: 3px;
  color: white;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}
.sidebarNavPanel {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebarButton {
  display: flex;
  gap: 10px;
  width: 200px;
  font-size: 13px;
  margin-left: 10px;
  padding-top: 3px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.sidebarButtonIcon {
  color: white;
}

.sidebarButtonIconLower1 {
  color: white;
  margin-top: 4.1rem;
}
.sidebarButtonIconLower2 {
  color: white;
  margin-top: 2.3rem;
}
.sidebarButton:hover {
  cursor: pointer;
  background: white;
  .buttonText {
    color: #1e1e2d;
  }
  .sidebarButtonIcon {
    color: #1e1e2d;
  }
}
.sidebarButton:hover svg{
  color: #1e1e2d !important;
}
.active {
  background: white;
  .buttonText {
    color: #1e1e2d;
  }
  .sidebarButtonIcon {
    color: #1e1e2d;
  }
}

.buttonText {
  color: white;
  margin-top: 3px;
}

.inventoryButton {
  display: flex;
  gap: 10px;
}

.inventorySpecial {
  gap: 40px;
}

.inventoryDropDown {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 200px;
}

.inventoryDDButton {
  display: flex;
  color: #82828c;
  padding-left: 1px;
  /* justify-content: center; */
  font-size: 13px;
  line-height: 20px;

  align-items: center;
  gap: 10px;
  margin-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.inventoryDDButton:hover {
  background: #2b2a3c;
  cursor: pointer;
  color: white;
}

.docsAndComps {
  color: #b5b5c3;
  background-color: #2b2a3c;
  padding: 5px;
  width: 50%;
  /* margin-left: 5px;
  margin-right: 5px; */
  /* margin-top: 150px; */
  margin-bottom: 10px;
  border-radius: 5px;
}

/* .manageButton {
  margin-left: 7px;
} */

@media screen and (min-width: 200px) and (max-width: 800px) {
  .sidebar {
    display: none;
  }
}

.filetrBtn{
  border: none !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background: #1e1e2d !important;
    color: white !important;
    border-radius: 5px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.filetrBtn svg{
  color: white !important;
  margin-top: 0px !important;
  background: rgba(0, 0, 0, 0) !important;
  font-size: 22px !important;
  padding: 1px !important;
}
