.settingsPage {
  display: flex;
}

.settingsPage .ordersHeader {
  background: white;
  top: 0;
  z-index: 1;
  position: sticky;
}
.settingsMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #ebecf1;
}

.settingsMainTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settingsMainSection {
  padding: 15px;
}
