:root {
  --softgray: #b5b5c3;
  --blue: #009ef7;
  --dark-blue: #1e1e2d;
  --black: #000000;
  --white: #ffffff;
  --hardgray: #7e8299;
  --bggray: #f9f9f9;
  --colorgray: #969aa8;
  --lightbluebg: #ebf4fa;
}

body {
  background: #fcfcfc;
  font-family: "Poppins", sans-serif;
}
*:focus {
  outline: none;
}

.orderPage {
  display: flex;
}
.orders {
  width: 100%;
  font-family: "Poppins", sans-serif;

  display: flex;
  background: #fcfcfc;
  flex-direction: column;
  justify-content: space-between;
}

.ordersMain th {
  color: var(--softgray) !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

.ordersMain .darkData {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
}

.ordersMain .lightData {
  color: var(--hardgray) !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
}

.ordersMain .coloredData {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
}

.ordersHeader {
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust z-index as needed */
  background: white;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 40px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.ordersMain .table-responsive .dropdown button {
  color: #969aa8;
  font-size: 12px;
  font-weight: 500;
  border: none;
  background: #f9f9f9;
}
.ordersMain .table-responsive .dropdown-menu {
  width: fit-content;
}
.ordersMain .table-responsive .show button {
  font-size: 12px;
  font-weight: 500;

  text-align: center;
  border: none;
  background: white;
}

.ordersMain .table-responsive .show button:hover {
  font-size: 12px;
  font-weight: 500;
  color: var(--blue);
  border: none;
  background: var(--lightbluebg);
}
.ordersHeaderLeft {
  padding-top: 8px;
  display: flex;
  gap: 15px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  color: #5e6278;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform: translateX(-95px);
  background: white;
  border-radius: 50%;
  /* position: fixed; */
}
.ordersHeaderLeft:hover {
  color: #009ef7;
}

.closedIcon {
  transform: translateX(-80px);
}

.oheaderButton {
  color: #5e6278;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  padding-top: 10px;
}

.oheaderButton:hover {
  color: #009ef7;
  background: #f9f9f9;
  cursor: pointer;
}

.ordersHeaderRight {
  display: flex;
  gap: 20px;
}

.oHeaderIcon {
  margin-top: 20px;
  color: #5e6278;
  margin-bottom: 20px;
  padding: 10px;
}

.oHeaderIcon:hover {
  color: #009ef7;
  background: #f9f9f9;
  cursor: pointer;
}
.profileImage {
  margin-top: 5px;
  /* margin-bottom: 40px; */
}

.profileImage img {
  border-radius: 5px;
}

.ordersMain {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 15px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}

.orders_box{
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
  background-color: #ebecf1;
}

.orderFilterList {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  /* border-bottom: #b5b5c3 0.3px solid; */
  height: 42px;
}

.orderFilterListButton {
  padding-left: 10px;
  display: flex;

  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: transparent;
  color: #1e1e2d;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  
}

.orderListButtonActive {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  display: flex;
  padding-bottom: 5px;
  background: #1e1e2d;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* color: black; */
  
}

.orderFilterListButton:hover {
  color: white;
  background: #1e1e2d;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.ordersTable {
  background: white;
  width: 100%;
  height: fit-content;
  padding: 30px;
}

.ordersMainTop {
  display: flex;
  justify-content: space-between;
}
.omTopLeft input {
  border: none;
  background-color: #f9f9f9;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.omTopLeft input :placeholder-shown {
  color: red;
}
.omTopLeft svg {
  color: #5e6278;
  background-color: #f9f9f9;

  padding: 5px;
  margin-top: 8px;
  margin-left: 3px;
}
.oSearch {
  display: flex;
}
.searchIcon {
  background: #f9f9f9;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.omTopRight {
  display: flex;
  gap: 30px;
}
.oDateRange {
  display: flex;
}
.dateCloseIcon {
  background: #f9f9f9;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.omTopRight input {
  background-color: #f9f9f9;
  border: none;
  width: 200px;
  padding: 10px;
  color: #f4f4f4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.omTopRight svg {
  color: #5e6278;
  background-color: #f9f9f9;
  padding: 5px;
  margin-top: 8px;
  margin-right: 3px;
}

.css-b62m3t-container {
  width: 150px;
  height: 30px;
}

.css-b62m3t-container:focus {
  border: none;
}

@media screen and (min-width: 200px) and (max-width: 800px) {
  .ordersMainTop {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .omTopRight {
    display: flex;
    flex-direction: column;
  }
  .ordersMainList {
    overflow: scroll;
    position: relative;
  }
}
@media screen and (min-width: 200px) and (max-width: 600px) {
  .orderFilterList {
    gap: 5px;
  }
  .orderFilterListButton {
    font-size: 10px;
    font-weight: 500;
    padding-right: 3px;
    padding-left: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .orderListButtonActive {
    font-size: 10px;
    font-weight: 500;
    padding-right: 3px;
    padding-left: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.countShower {
  /* background: white;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px; */
  /* color: #fff; */
  font-size: 11px;
  margin-left: 5px;
  /* border-radius: 4px; */
}

.darkDataClick:hover {
  background: #f4f4f4;
}


.profile_toggle{
  width: 35px;
  height: 40px;
  aspect-ratio: 2 / 2;
  object-fit: cover;
}


.dropdown-menu.show{
  transform: translate(0px, 40px) !important;
    border: none;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.1);
    outline: none !important;
}

.custom-page-link {
  color: #1e1e2d !important; /* Set the text color to black */
}

.custom-page-link:hover {
  color: darkgray !important; /* Optional: Set a hover color */
}

.pagination .active .custom-page-link {
  color: white !important; /* Optional: Set active page link color */
  background-color: #1e1e2d !important; /* Optional: Set active page background color */
  border-color: #1e1e2d !important; /* Optional: Set active page border color */
}



