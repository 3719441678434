/* CouponCode.css */

.couponCodePage {
  display: flex;
}

.couponCodeMain {
  flex-grow: 1;
}

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px); /* Adjust based on your header and footer height */
  background-color: #f3f4f6; /* bg-gray-100 */
}

.coming-soon-box {
  background-color: rgb(99, 194, 238);
  padding: 2rem; /* Equivalent to p-8 */
  border-radius: 0.5rem; /* Equivalent to rounded-lg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-lg */
  text-align: center;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coming-soon-box h1 {
  font-size: 1.875rem; /* Equivalent to text-3xl */
  font-weight: 700; /* Equivalent to font-bold */
  color: #1f2937; /* Equivalent to text-gray-800 */
  margin-bottom: 1rem;
}

.coming-soon-box p {
  font-size: 1rem; /* Equivalent to text-lg */
  color: #1f2937; /* Equivalent to text-gray-800 */
  margin-bottom: 1.5rem;
}

.coming-soon-box .thank-you {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #1f2937; /* Equivalent to text-gray-800 */
}
