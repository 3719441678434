.add-slider {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .add-slider-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .upload-image input[type="file"] {
    display: none;
  }
  
  .upload-image p {
    font-size: 14px;
    color: #888;
  }
  
  .upload-image label {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .add-slider input[type="text"],
  .add-slider select,
  .add-slider input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .select-type {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .select-type label {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .select-type input[type="radio"] {
    margin-right: 5px;
  }
  
  .date-range {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .active-switch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .active-switch label {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .save-slider-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
  }
  