.qrCodePage {
  display: flex;
}

.qrCodeMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fcfcfc;
}
.qrCodeMainSec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrCard {
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 7px;
  background: white;
}
.qrCodeTitle {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.qrCodeMainTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.qrCodeHolder {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.qrCodeStoreName {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.qrCodeCustomerLink {
  font-size: 14px;
  line-height: 20px;
  color: #a1a5b7;
  font-weight: 600;
}
.qrCodeNumber {
  font-size: 14px;
  line-height: 20px;
  color: #009ef7;
  font-weight: 600;
}

.qrCallForAction {
  font-size: 14px;
  line-height: 21px;
  color: #f1416c;
  font-weight: 500;
}

.qrCodeButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.qrButton {
  background: #009ef7;
  color: white;
  display: flex;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
}

.qrButton svg {
  margin-top: 4px;
}
