.signupPage {
  /* font-family: Inter, 'Helvetica', sans-serif; */
  font-family: "Poppins", sans-serif;
  background-image: linear-gradient(90deg, #50b5e7, #2ebf91);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signUpForm {
  background: white;
  width: fit-content;
  border-radius: 7px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signUpPhoto {
  display: flex;
  flex-direction: column;
}

.signUpFormContentLine {
  display: flex;
  gap: 20px;
}
.signUpFormContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.signUpInputs {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.signUpInputs label {
  text-align: start;
}

.signUpInputs label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.signUpInputs input {
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.signUpSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signUpSubmitButton {
  background: #1e1e2d;
  color: white;
  width: fit-content;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.signUpSubmitButton:hover {
  cursor: pointer;
}
.signUpSubmitButtonLoad {
  background: #4e4e5f;
  cursor: not-allowed;
  width: 130px;
}

.optionIcons {
  display: flex;
  justify-content: center;
  gap: 50px;
  transform: translateY(-20px);
}
