.formLoader {
  width: 69%;
  margin-left: 32%;
  display: flex;

  flex-direction: column;
  gap: 30px;
}

.formImageSkel {
  width: 150px;
  height: 150px;
}

.formLoaderComp {
}

.formLoaderComponent {
  background: white;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.formLoaderComponent1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}

@media screen and (min-width: 200px) and (max-width: 1000px) {
  .formLoader {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 550px) {
  .circularShimmer {
    size: 90px !important;
  }
  .thumbnailShimmer {
    width: 175px !important;
  }
}
