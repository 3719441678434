:root {
  --softgray: #b5b5c3;
  --blue: #009ef7;
  --dark-blue: #1e1e2d;
  --black: #000000;
  --white: #ffffff;
  --hardgray: #7e8299;
  --bggray: #f9f9f9;
  --colorgray: #969aa8;
  --lightbluebg: #ebf4fa;
}
.catalogMainUp{
  display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: #ebecf1;
}
.catalogPage {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.catalogMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fcfcfc;
}

.catalogMain .ordersHeader {
  background: white;
}

.catalogMainSec {
  /* border: 1px solid black; */
  /* margin: 15px; */
}

.catalogList {
  /* margin: 10px; */
  padding: 15px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
}

.catalogListButtons {
  display: flex;
  justify-content: space-between;
}

.searchIcon {
  background: #f9f9f9;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.catalogPage .oSearch input {
  border: none;
  background-color: #f9f9f9;
  padding: 5px;
  font-family: "Poppins", sans-serif;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.catalogPage .oSearch svg {
  color: #5e6278;
  background-color: #f9f9f9;
  padding: 5px;
  margin-top: 3px;
  margin-left: 3px;
}

.catalogListAbove {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.categoriesText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addCategoryButton {
  background: #009ef7;
  color: white;
  font-size: 14px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
}

.categoriesText div {
  text-align: start;
}

.filterAndCreateButton {
  display: flex;
  gap: 20px;
}

.filterButton {
  display: flex;
  gap: 10px;
  background: #cdcdd0;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  padding-top: 14px;
  height: 45px;
  font-weight: 600;
  border-radius: 5px;
}

.filterButton svg {
  margin-top: 2px;
}

.catalogListAbove .filterButton {
  display: flex;
  gap: 10px;
  background: white;
  color: #7e8299;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  padding-top: 10px;
  height: 40px;
  font-weight: 600;
  border-radius: 5px;
}

.filterButton:hover {
  cursor: pointer;
  color: #009ef7;
}

.catalogListAbove .createButton {
  background: #009ef7;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  padding-top: 10px;
  height: 40px;
  font-weight: 600;
  border-radius: 5px;
  color: white;
}
.createButton:hover {
  background: #0190e2;
  color: white;
  cursor: pointer;
}
.catalogMain .table-responsive {
  font-family: "Poppins", sans-serif;
}

.catalogMain .table-responsive .catalogImageAndTitle {
  color: var(--hardgray);
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
}

.catalogMain .table-responsive .catalogStatus {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
}

.catalogMain .table-responsive th {
  color: var(--softgray);
  line-height: 19px;
  font-size: 12px;
  font-weight: 600;
}

.catalogMain .table-responsive .countOfProducts {
  font-weight: 600;
  color: #ffc700 !important;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
}

.catalogActionsDropdown {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
}

.catalogActionsDropdown .dropdown-toggle {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
}

.catalogActionsDropdown .show {
  font-size: 12px;
  width: 50px;
  line-height: 19px;
  font-weight: 600;
}

.custom-dropdown-menu {
  width: 40px !important;
}

@media screen and (min-width: 200px) and (max-width: 800px) {
  .catalogMainSec .catalogListAbove {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .catalogListButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .catalogListMainList {
    overflow: scroll;
  }
}
.no-underline {
  text-decoration: none;
}
.pagination-link {
  padding: 0.5rem 1rem; /* Consistent padding */
  margin: 0 0.25rem; /* Consistent margin */
  line-height: 1.5; /* Ensures text is vertically centered */
  text-decoration: none; /* Removes underline */
  display: inline-block; /* Ensures the button stays in line */
  color: #adaeaf; /* Default text color */
}

.pagination-link:hover {
  background-color: #e5e7eb; /* Hover background color */
}

.active-page .pagination-link {
  background-color: #e5e7eb; /* Active background color */
  padding: 0.5rem 1rem; /* Consistent padding */
}

.previous-link, .next-link {
  background-color: black; /* Black background */
  color: white; /* White text */
}

