.managePage {
  display: flex;
  font-family: "Poppins", sans-serif;
}
.managePageMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ebecf1;
}
.managePageMainSec {
  margin-top: 10px;
  padding: 20px;
  /* display: grid;
  grid-template-columns: auto auto auto; */
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
}

.display-grid-item {
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  /* font-size: 30px; */
  text-align: center;
}

.display-grid-item:hover {
  cursor: pointer;
}

.display-grid-item-line {
  text-align: start;
  color: #1e1e2d;
}

.display-grid-item-line svg {
  font-size: 30px;
}

.display-grid-item-line-title {
  font-size: 20px;
  line-height: 29px;
  font-weight: 600;
}

.display-grid-item-line-subtitle {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

.badge.badge-info {
  color: #ffffff;
  background-color: #0dcaf0;
  font-size: 12px;
}