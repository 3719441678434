.lofazFooter {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  background: #ebecf1;
  height: 60px;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
}

.lofazFooterLeft {
  display: flex;
  gap: 10px;
}

.lofazFooterRight {
  display: flex;
  gap: 10px;
}
