.addProductForm {
  width: 69%;
  margin-left: 32%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.addProductFormComponent {
  background: white;
  border-radius: 12px;
}

.productMediaForm {
  display: flex;
  padding: 15px;
  gap: 10px;
}

.featureImageBottomText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
}

.productMediaLeft {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.productMediaRight {
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropFilesSection {
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 10px;
  border: dashed 1px #009ef7;
  border-radius: 5px;
  background: #f1faff;
  padding-top: 50px;
  padding-bottom: 55px;
  padding-left: 40px;
}

.dropFilesTexts {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.productInfoComponent {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.productInfoTitle {
  text-align: left;
  color: #181c32;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

.productNameAndCategoryInputs {
  display: flex;

  gap: 40px;
}

.inputName {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 10px;
}

.inputCategory {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}
.inputSKUAdd {
  width: 30%;
}

.inputQuantityAdd {
  width: 30%;
}
.css-b62m3t-container {
  width: 100%;
}

.ql-toolbar .ql-formats {
  color: #cccccc;
}

.ql-editor {
  min-height: 300px;
}
.ql-editor.ql-blank::before {
  color: #6f7284 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-style: normal !important;
}

.inputHeading {
  text-align: left;
  color: #3f4254;
  font-weight: 500;
}

.productMSC {
  display: flex;
  gap: 10%;
}
.productMSCcomponent {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 10px;
}

.productMSC input {
  border: 1px solid #e1e3ea;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.productMSC input::placeholder {
  color: #a1a5b7;
  font-size: 14px;
  font-weight: 500;
}

.costText {
  color: #a1a5b7;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

.productFormInventoryComponent {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}

.inventoryTitle {
  text-align: left;
  color: #181c32;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

.inventoryInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inventoryInputs input {
  border: 1px solid #e1e3ea;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inventoryInputs input::placeholder {
  color: #a1a5b7;
  font-size: 14px;
  font-weight: 500;
}

.inventoryInputs span {
  color: red;
}

.inputSKU {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 45%;
}

.inputQuantity {
  display: flex;
  gap: 7px;
  flex-direction: column;
  width: 45%;
}

.inputSKUAdd {
  width: 30% !important;
}

.inputQuantityAdd {
  width: 30% !important;
}

.inputInventoryTitle {
  text-align: left;
  color: #3f4254;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.prodcutFormSEOComponent {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.seoTitle {
  text-align: left;
  color: #181c32;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

.seoFormComp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.seoCompInfo {
  color: #a1a5b7;
  text-align: left;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
}

.metaTagTile {
}

.seoCompSubtitles {
  text-align: left;
  color: #3f4254;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.seoFormComp input {
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #e1e3ea;
  border-radius: 5px;
}

.seoFormComp input::placeholder {
  color: #a1a5b7;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.tagsSubtitle {
  font-size: 16px;
}

.saveButtonComponent {
  display: flex;
  justify-content: end;
  background: #fcfcfc;
  gap: 30px;
}

.saveButtonComponent .saveButton {
  background: #009ef7;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  color: white;
}

.saveButtonComponent .cancelButton {
  padding-left: 50px;

  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fcfcfc;
  border-radius: 10px;
}

.saveButtonComponent .saveButton:hover {
  cursor: pointer;
}

.saveButtonComponent .cancelButton:hover {
  background: #eff1f4;
  cursor: pointer;
}
