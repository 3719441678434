.smallSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100vh;
}
.smallSidebarTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallSidebarHeader {
  height: 50px;
  border-bottom: dashed #82828c;
  border-width: 1px;
}
.smallSidebarButtons {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.hidden-notification {
  display: none;
}

.hidden-notification.active {
  display: block;
}