.signInPage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signInPanel {
  background: white;
  width: 35%;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.signInFormComp {
  align-content: center;
}

.signInFormWithOTP {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.otpInputPanelwithButton {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.otpBoxes {
  display: flex;
  gap: 30px;
}

.OTPInputLabel {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #181c32;
}

.OTPSubmit:hover {
  cursor: pointer;
}

.OTPNumberUpdateComp span:hover {
  cursor: pointer;
}

.verifyButton:hover {
  cursor: pointer;
}

.agreeButton {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
}

.agreeButton input {
  border: 1px solid #e1e3ea;
  height: 12px;
  width: 12px;
  margin-top: 5px;
  border-radius: 6px;
}

.agreeButton div {
  margin-top: 3px;
  color: #5e6278;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}
.verifyButton {
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  background: #009ef7;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.countDownButtonActive {
  background-color: #009ef7;
  border-radius: 7px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}

.countDownButtonInactive {
  background-color: #8bd5ff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 7px;
  padding-top: 10px;
  color: white;
  padding-bottom: 10px;
}

.OTPSubmitAndResend {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 1000px) and (min-width: 800px) {
  .signInPanel {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .signInPanel {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .signInPanel {
    width: 75%;
  }
}

@media only screen and (max-width: 400px) and (min-width: 250px) {
  .signInPanel {
    width: 100%;
  }
}
