:root {
  --bgGray: #f4f4f4;
  --pHGray: #a1a5b7;
  --darkGray: #5e6278;
  --lightGray: #f9f9f9;
}

.editProfileForm {
  width: 70%;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.editProfileFormSection {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  border-radius: 7px;
}
.editProfileSecTitle {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  text-align: start;
}
.editProfileSecContent {
  display: flex;
  gap: 30px;
}
.editProfileSecContent2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.editProfileSecContent2 .line {
  display: flex;
  gap: 30px;
}

.editProfileSecContent2 .line .inputAndLabel {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.editProfileSecContent2 .line .inputAndLabel input {
  height: 35px;
  padding-left: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #5e6278;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 5px;
}
.countryDropdown {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  width: 200px;
  border-radius: 5px;
  border: 0.2px solid var(--pHGray);
}

.stateDropdown {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 200px;
  padding-right: 15px;
  border-radius: 5px;
  border: 0.2px solid var(--pHGray);
}
.editProfileSecContent2 .line .label {
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.profileImageAdder {
  /* border: 1px dashed #009ef7; */
}
.profileDetailsAdder {
  display: flex;
  width: 70%;
  gap: 30px;
}

.profileInputLabel {
  display: flex;
  justify-content: space-between;
}

.profileInputLabel p {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.profileDetailsAdder input:disabled {
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  background: var(--bgGray);
  border: 1px solid rgba(255, 255, 255, 0);
  height: 35px;
}
.profileDetailsAdder input  {
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #a1a5b7;
  height: 35px;
}

.profileDetailsAdder input::placeholder {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--pHGray);
}

.editProfileSecContent2 p {
  margin-bottom: 4px;
  text-align: start;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
}

.editProfileSecContent2 input {
  border-radius: 5px;
  background: white;
  border: 0.5px solid var(--pHGray);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  height: 35px;
}

.editProfileSecContent2 input::placeholder {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: var(--darkGray);
}

.aboutStoreTitle {
  text-align: start;
  padding-left: 4px;
  font-weight: 500;
  line-height: 21px;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.storeStatusForm {
  width: 65%;
  margin-left: 30%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 30px;
  padding-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.storeStatusTitleForm {
  text-align: start;
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  padding: 10px;
}

.storeStatusFormComponent {
  border-top: #a1a5b7 0.3px dashed;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.storeStatusFormCompLeft {
  display: flex;
  gap: 10px;
}

.storeStatusFormCompLeft svg {
  margin-top: 5px;
}

.storeStatusTitle {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1px;
}

.subscetionTitle {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #1e1e2d;
}

.subsectionContent {
  font-size: 14px;
  font-weight: 600;
  color: #b5b5c3;
  line-height: 21px;
}

.privacyPolicyForm {
  width: 65%;
  margin-left: 30%;
  /* background: white; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.privacyPolicyFormComponent {
  background: white;
  /* border-radius: 7px; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  /* padding: 0; */
}
/* .privacyPolicyFormComponent1{

} */

.privacyPolicyFormComponent1 {
  position: fixed;
  top: 50px;
  background: white;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 99;
  width: 55%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 0;
}
.privacyPolicyFormComponent2 {
  margin-top: 15%;
}
.privacyPolicyInner {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 15px;
  border-radius: 5px;
}

.scrollButton {
  font-size: 14px;
  line-height: 21px;
  color: #5e6278;
  font-weight: 500;
}

.scrollButton:hover {
  cursor: pointer;
  color: #1e1e2d;
}

.policiesTitle {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  text-align: start;
}

.policiesSubtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: start;
  color: #5e6278;
}

.createFromTemplateButton {
  text-align: start;
  background: #009ef7;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  color: white;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
}

.createFromTemplateButton:hover {
  cursor: pointer;
  background: #0287d4;
}

.socialMediaForm {
  width: 65%;
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.socialMediaFormComponent {
  background: white;
  padding: 20px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.socialMeidaInputLeft {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socialMeidaInputRight {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socialMediaTitle {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  text-align: start;
}
.socialMediaInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.socialMediaInput .inputLabel {
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.socialMediaInput .bar {
  background: var(--lightGray);
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top: 0.3px solid #8e8e92;
  border-left: 0.3px solid #8e8e92;
  border-bottom: 0.3px solid #8e8e92;
  border-right: none;
  height: 45px;
  padding: 10px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--darkGray);
}

.socialMediaInput input {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top: 0.3px solid #8e8e92;
  border-left: 0.1px solid #8e8e92;
  padding-left: 10px;
  border-right: 0.3px solid #8e8e92;
  border-bottom: 0.3px solid #8e8e92;
}

.cancelAndSaveButton {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.saveButton {
  background: #009ef7;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.saveButton:hover {
  background: #0287d4;
  cursor: pointer;
}

.cancelButton {
  background: var(--pHGray);
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding-right: 25px;
  border-radius: 5px;
  color: white;
}

.socialMediaForm .cancelButton:hover {
  background: var(--darkGray);
  cursor: pointer;
}

.shippingAndDeliveryForm {
  width: 65%;
  padding: 0px;
  margin-left: 30%;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shippingAndDeliveryFormComponent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: white;
}

.shippingAndDeliveryTitle {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  text-align: start;
}

.shippingAndDeliveryContent {
  display: flex;
  gap: 20px;
}

.sAndDinput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sAndDinput .inputLabel {
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.sAndDinput input {
  width: 300px;
  height: 35px;
  border-radius: 5px;
  border: 0.1px solid #8e8e92;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.sAndDinput input::placeholder {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--pHGray);
}

.storeTimingForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 30%;
  width: 65%;
}

.storeTimingFormComponent {
  padding-top: 20px;
  border-radius: 7px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.storeTimingFormTitle {
  text-align: start;
  padding-left: 20px;
  font-size: 20px;
  padding-bottom: 40px;
  border-bottom: 0.05px solid #8e8e92;
  line-height: 23px;
  font-weight: 600;
}

.timeAndDatePickers {
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
}

.dayPicker {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dayPicker button {
  width: 150px;
  display: flex;
  justify-content: space-between;
  background: var(--bgGray);
  color: var(--darkGray);
  border: none;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.dayPicker svg {
  margin-top: 4px;
}

.timePicker {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dayLabel {
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.timeLabel {
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.storeTimingFormContent {
  padding-left: 20px;
  padding-top: 20px;
}

.timePicker input {
  border: none;
  background: var(--bgGray);
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--darkGray);
}

.deleteButton {
  margin-top: 30px;
}

.deleteButton svg:hover {
  color: red;
  cursor: pointer;
}

.buttonHolders {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 1200px) and (min-width: 800px) {
  .navigationBoxSettings {
    position: relative;
    width: 90%;
  }
  .settingsMainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .editProfileForm {
    margin-left: 0px;
    width: 90%;
  }

  .storeStatusForm {
    width: 90%;
    margin-left: 0px;
  }
  .shippingAndDeliveryForm {
    width: 90%;
    margin-left: 0px;
  }
  .socialMediaForm {
    width: 90%;
    margin-left: 0px;
  }
  .privacyPolicyForm {
    width: 90%;
    margin-left: 0px;
  }
  .privacyPolicyFormComponent1 {
    width: 90%;
    display: none;
  }
  .scrollButton {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
  .navigationBoxSettings {
    position: relative;
    width: 90%;
  }
  .settingsMainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .editProfileForm {
    margin-left: 0px;
    width: 90%;
  }
  .editProfileSecContent2 input {
    width: 150px;
  }
  .stateDropdown {
    width: 100px;
  }
  .countryDropdown {
    width: 100px;
  }
  .editProfileSecContent2 .line .inputAndLabel input {
    width: 100px;
  }

  .storeStatusForm {
    width: 90%;
    margin-left: 0px;
  }

  .shippingAndDeliveryForm {
    width: 90%;
    margin-left: 0px;
  }
  .sAndDinput input {
    width: 220px;
  }

  .socialMediaForm {
    width: 90%;
    margin-left: 0px;
  }
  .socialMediaInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .privacyPolicyForm {
    width: 90%;
    margin-left: 0px;
  }
  .privacyPolicyFormComponent1 {
    width: 90%;
    display: none;
  }

  .scrollButton {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }
}

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .navigationBoxSettings {
    position: relative;
    width: 90%;
  }
  .settingsMainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .editProfileForm {
    margin-left: 0px;
    width: 90%;
  }
  .line1EditSection {
    display: flex;
    flex-direction: column;
  }
  .firstNameAdder {
    display: flex;
    gap: 10px;
  }
  .lasttNameAdder {
    display: flex;
    gap: 10px;
  }
  .mailAdder {
    display: flex;
    gap: 10px;
  }
  .editProfileSecContent2 p {
    margin-bottom: 0px;
    margin-top: 6px;
  }
  .editProfileSecContent2 input {
    width: 250px;
  }
  .profileDetailsAdder {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .stateDropdown {
    width: 200px;
  }
  .countryDropdown {
    width: 200px;
  }
  .editProfileSecContent2 .line .inputAndLabel input {
    width: 200px;
  }
  .editProfileSecContent2 .line {
    display: flex;
    flex-direction: column;
  }
  .profileImageAdder img {
    width: 75px;
    height: 75px;
    
  }

  .storeStatusForm {
    width: 90%;
    margin-left: 0px;
  }
  .storeStatusFormComponent {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .shippingAndDeliveryForm {
    width: 90%;
    margin-left: 0px;
  }
  .sAndDinput input {
    width: 150px;
  }

  .socialMediaForm {
    width: 90%;
    margin-left: 0px;
  }
  .socialMediaInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .privacyPolicyForm {
    width: 90%;
    margin-left: 0px;
  }
  .privacyPolicyFormComponent1 {
    width: 90%;
    display: none;
  }

  .scrollButton {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  }
}

@media only screen and (max-width: 450px) and (min-width: 300px) {
  .shippingAndDeliveryContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .sAndDinput input {
    width: 200px;
  }
  .socialMediaInput input {
    width: 100%;
  }
}
